<p-growl id="growl" [value]="_messageService.messages" life="3000"></p-growl>

<p-confirmDialog acceptLabel="Sim" width="425" rejectLabel="Não" #cd>
  <footer>
    <button id="nao" type="button" pButton icon="fa-close" label="Não" (click)="cd.reject()"></button>
    <button id="sim" type="button" pButton icon="fa-check" label="Sim" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>

<!--
BODY TAG OPTIONS:
=================
Apply one or more of the following classes to get the
desired effect
|---------------------------------------------------------|
| SKINS         | skin-blue (PADRAO)                      |
|               | skin-black (CRIADA)
|               | skin-blue-white (CRIADA)                |
|---------------------------------------------------------|
|LAYOUT OPTIONS | fixed                                   |
|               | layout-boxed                            |
|               | layout-top-nav                          |
|               | sidebar-collapse                        |
|               | sidebar-mini                            |
|---------------------------------------------------------|
-->

<!-- <body class="hold-transition skin-blue sidebar-mini"> -->
<!-- <div class="wrapper" >-->

<!-- Main Header  Cabecaçalho -->
<header class="main-header" *ngIf="authService.isLoggedIn">

  <!-- Logo -->
  <a href="#" class="logo">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <span class="logo-mini">
      <!--<img class="responsive-img" src="../assets/img/logo/icon/neon-icon-white.png">-->
      <img class="responsive-img" src="../assets/img/logo/icon/logo-icon.png">
      <!--<img class="responsive-img" src="../assets/img/logo/icon/logo-icon-border-7.png">-->
    </span>
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg">
      <!--<img class="responsive-img" src="../assets/img/logo/pequeno/logo-white.png"> -->
      <img class="responsive-img" src="../assets/img/logo/pequeno/logo.png">
      <!--<img class="responsive-img" src="../assets/img/logo/pequeno/logo-border-15.png">-->
    </span>
  </a>

  <!-- Header Navbar -->
  <nav class="navbar navbar-static-top" role="navigation">
    <!-- Sidebar toggle button-->
    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
      <span class="sr-only">Toggle navigation</span>
    </a>
    <!-- Navbar Right Menu -->
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav" style="    padding-top: 6px;">
        <li class="dropdown messages-menu">
          <a href="https://api.whatsapp.com/send?phone=556239334300&text=Enviando%20mensagem"
            target="_blank"
            style="cursor: pointer">
            <i class="fa fa-whatsapp" style="font-size: 21px;"></i></a>
        </li>
        <li class="dropdown messages-menu" style="margin-top: -5px">
          <a href="https://servidorseguro.mysuite.com.br/client/login.php?sl=arg" target="_blank">
          <button type="button" class="btn  btnWhatsapp">Suporte</button></a>
        </li>
        <!-- Messages: style can be found in dropdown.less-->
        <li class="dropdown messages-menu" *ngIf="false">
          <!-- Menu toggle button -->
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fi-dashboard-opening-email"></i>
            <span class="label label-danger">4</span>
          </a>
          <ul class="dropdown-menu" >
            <li class="header"> 4 Mensagens </li>
            <li>
              <!-- inner menu: contains the messages -->
              <ul class="menu">
                <li>
                  <!-- start message -->
                  <a href="#">
                    <div class="pull-right">
                      <!-- User Image -->
                      <img src="../assets/img/logo-usuario.png" class="img-circle" alt="User Image">
                    </div>
                    <!-- Message title and timestamp -->
                    <h4>
                      Mensagens
                      <small>
                        <i class="fa fa-clock-o"></i> 5 mins</small>
                    </h4>
                    <!-- The message -->
                    <p> Local Reservado para Alerta. </p>
                  </a>
                </li>
                <!-- end message -->
              </ul>
              <!-- /.menu -->
            </li>
            <li class="footer">
              <a href="#"> Acessar as Mensagens </a>
            </li>
          </ul>
        </li>
        <!-- /.messages-menu -->

        <!-- Notifications Menu -->
        <li class="dropdown notifications-menu">
          <!-- Menu toggle button -->
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="fi-dashboard-bell-outline"></i>
            <span class="label label-danger">10</span>
          </a>
          <ul class="dropdown-menu">
            <li class="header"> Notificações </li>
            <li>
              <!-- Inner Menu: contains the notifications -->
              <ul class="menu">
                <li>
                  <!-- start notification -->
                  <a href="#">
                    <i class="fa fa-users text-aqua"></i> Vendas / Financeiro
                  </a>
                </li>
                <!-- end notification -->
              </ul>
            </li>
            <li class="footer">
              <a href="#">Acessar as notificações</a>
            </li>
          </ul>
        </li>

        <!-- User Account Menu -->
        <li class="dropdown user user-menu">
          <!-- Menu Toggle Button -->
          <a href="#" class="dropdown-toggle loggon-user" data-toggle="dropdown">
            <!-- The user image in the navbar-->
            <img src="{{globalsVariablesService.getUsuarioLogado.caminhoImagem}}" class="user-image" alt="User Image">
            <!-- hidden-xs hides the username on small devices so only the image appears. -->
            <span class="hidden-xs"> {{globalsVariablesService.getUsuarioLogado.pessoaNomeFantasia}} </span>
          </a>
          <ul class="dropdown-menu">
            <!-- The user image in the menu -->
            <li class="user-header">
              <img src="{{globalsVariablesService.getUsuarioLogado.caminhoImagem}}" class="img-circle" alt="User Image">
              <p>
                <small>{{globalsVariablesService.getUsuarioLogado.pessoaNomeFantasia}} <br> Usuário desde Nov.
                  2012</small>
              </p>
            </li>
            <!-- Menu Body -->
            <!--
                <li class="user-body">
                  <div class="row">
                    <div class="col-xs-4 text-center">
                      <a href="#">Followers</a>
                    </div>
                    <div class="col-xs-4 text-center">
                      <a href="#">Vendas</a>
                    </div>
                    <div class="col-xs-4 text-center">
                      <a href="#">Usuarios</a>
                    </div>
                  </div>
                </li>.row -->

            <!-- Menu Footer-->
            <li class="user-footer">
              <div class="pull-right">
                <a routerLink="/seguranca/usuario" class="btn btn-default btn-flat">Perfil</a>
              </div>
              <div class="pull-right">
                <a (click)="logout()" class="btn btn-default btn-flat">Logout</a>
              </div>
            </li>
          </ul>
        </li> <!-- Fazer Logout -->
        <li>
          <a (click)="logout()" data-toggle="control-sidebar">
            <i class="fa fa-power-off"></i>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
<!-- right side column. contains the logo and sidebar -->
<aside class="main-sidebar" [ngClass]="{'show':authService.isLoggedIn}">

  <!-- sidebar: style can be found in sidebar.less -->
  <section class="sidebar">

    <!-- Sidebar user panel (optional) -->
    <div class="user-panel">
      <div class="pull-right image">
        <img src="{{globalsVariablesService.getUsuarioLogado.caminhoImagem}}" class="img-circle" alt="User Image">
      </div>
      <div class="pull-right info">
        <p><span>Olá</span>,<br>{{globalsVariablesService.getUsuarioLogado.pessoaNomeFantasia}}</p>
        <!-- Status -->
        <a href="#">
          <i class="fa fa-circle text-success"></i> Online</a>
      </div>
    </div>

    <!-- search form (Optional) -->
    <form action="#" method="get" class="menu-search sidebar-form">
      <div class="input-group">
        <input type="text" name="q" class="form-control" placeholder="Pesquisar...">
        <span class="input-group-btn">
          <button type="submit" name="search" id="search-btn" class="btn btn-flat">
            <i class="fi-interface-search"></i>
          </button>
        </span>
      </div>
    </form>
    <!-- /.search form -->

    <!-- Sidebar Menu -->
    <ul class="sidebar-menu tree" data-widget="tree">

      <li class="treeview" class="menuSelecionado">
        <a class="rota" routerLink="/dashboard">
          <i class="rota fi-dashboard-graphic"></i>
          <span class="rota"> Dashboard </span>
        </a>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-dashboard-dashboard"></i>
          <span>Administrativo</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <!--
            <li *ngIf="authService.visualizarEmpresa">
                <a class="rota" routerLink="/administrativo/empresa">
                    <span class="rota icon"> <i class="fa fa-circle-o"></i> Empresa </span>
                </a>
            </li>
          -->
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarLoja" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/loja">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Loja </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarColaborador" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/colaborador">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Colaborador </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarColaborador" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/colaborador-comissao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Incluir Comissão </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFavorecido" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/favorecido">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Favorecido </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFavorecido" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/contador">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Contador </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFaixaRenda" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/faixa-renda">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Faixa de Renda </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTransportadora" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/transportadora">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Transportadora </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarProfissao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/profissao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Profissão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConceito" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/conceito">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Conceito </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRamoAtividade" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/ramo-atividade">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Ramo de Atividade </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTipoWeb" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/tipo-web">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Tipo WEB </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTipoTelefone" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/tipo-telefone">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Tipo Telefone </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSituacaoCliente" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/situacao-cliente">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Situação Cliente </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCredenciadorCartao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/credenciadora-cartao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Credenciadora Cartão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBandeiraCartao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/bandeira-cartao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Bandeira Cartão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBandeiraCartao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/contrato">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Contrato </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBandeiraCartao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/atualiza-status">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Atualiza Status Cliente </span>
            </a>
          </li>

        </ul>
      </li>
      <!-- [ngClass]="isMenuAberto(event) ? 'fa-angle-down' : ''" -->
      <li class="treeview">
        <a>
          <i class="fi-ecommerce-package-6"></i>
          <span>Estoque</span>
          <i class="fa fa-angle-right pull-right" ></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCustoOperacional" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/custo-operacional">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Custo Operacional </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCategoria" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/categoria">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Categoria </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarMarca" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/marca">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Marca </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFabricante" class="menuSelecionado">
            <!-- Fabricante -->
            <a class="rota" routerLink="/estoque/fabricante">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fabricante </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarUnidade" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/unidade">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Unidade </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarUnidade" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/grupo-equivalente">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo Equivalente </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/lista-preco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Lista de Preço </span>
            </a>
          </li>

          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarProduto" -->
          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/estoque/grade-variacao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grade Variação </span>
            </a>
          </li>

          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/estoque/grade-produto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grade Produto </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarProduto" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/produto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Produto </span>
            </a>
          </li>

          <!-- <li *ngIf="globalsVariablesService.getPermissoes.visualizarServico" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/servico">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Serviço </span>
            </a>
          </li> -->

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarAtualizaPreco" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/atualiza-preco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Atualiza Preço </span>
            </a>
          </li>

         <li *ngIf="globalsVariablesService.getPermissoes.visualizarAtualizaPreco" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/correcao-estoque">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Correção de estoque </span>
            </a>
          </li>

          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/estoque/manutencao-produto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Manutenção Produto </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGerarBalanco" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/gerar-balanco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Gerar Balanço </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTransferenciaLojas" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/transferencia-lojas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Transferência entre Lojas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConfirmarTransferencia" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/confirmar-transferencia">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Confirmar Transferências </span>
            </a>
          </li>

        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-ecommerce-shopping-cart-8"></i>
          <span>Vendas</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPedido" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/pedido">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Pedido </span>
            </a>
          </li>
         <li *ngIf="globalsVariablesService.getPermissoes.visualizarPedido" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/altera-nfe">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Altera Nfe </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPDV" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/pdv">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> PDV </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/comanda">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Comanda </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCancelamentoPedido" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/cancelamento-pedido">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Cancelamento Pedido</span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCancelamentoPedido" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/carta-correcao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Carta de Correção 
              </span>
            </a>
          </li>
          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/comercial/inutilizacao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Inutilização NFCE/NFE</span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupoDesconto" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/grupo-desconto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo Desconto </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarComissaoDesconto" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/comissao-desconto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Comissão por Desconto </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTabelaFaturamento" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/tabela-faturamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Tabela Faturamento </span>
            </a>
          </li>
        </ul>
      </li>


      <li class="treeview">
        <a>
          <i class="fi-ecommerce-shopping-cart-8"></i>
          <span>Compras</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPedidoCompra" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/pedido-compra">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Pedido de Compra</span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCancelamentoPedidoCompra" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/cancelamento-pedido-compra">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Cancelamento de Compra</span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarEntradaCompra" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/entrada-compra">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Entrada por Compra</span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCancelamentoEntrada" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/cancelamento-entrada">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Cancelamento de Entrada</span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarImportacaoCompraXML" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/importacao-compra">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Importação de XML </span>
            </a>
          </li>
          <li class="menuSelecionado">
            <a class="rota" routerLink="/comercial/sugestao-compra">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Sugestão de Compra</span>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-ecommerce-shopping-cart-8"></i>
          <span>Devoluções </span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDevolucaoFornecedor" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/devolucao-fornecedor">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Devolução Fornecedor </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDevolucaoCliente" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/devolucao-cliente">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Devolução Cliente </span>
            </a>
          </li>
        </ul>
      </li>



      <li class="treeview">
        <a>
          <i class="fi-ecommerce-pen"></i>
          <span>Fiscal</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupoTributoProduto" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/grupo-tributo-produto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo Tributo Produto </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupoTributoFavorecido" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/grupo-tributo-favorecido">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo Tributo Cliente </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarNCM" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/ncm">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> NCM </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarModeloDocumentoFiscal" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/modelo-documento-fiscal">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Modelo Documento Fiscal </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCFOP" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/cfop">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> CFOP </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConvenio" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/convenio">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Convênio </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSituacaoTributaria" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/situacao-tributaria">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Situação Tributária </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConfiguracaoTributo" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/configuracao-tributo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Configuração Tributo </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarOperacao" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/operacao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Operação </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSintegra" class="menuSelecionado">
            <!-- Adicionar novas funcionalidades para tela -->
            <a class="rota">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Sintegra </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSpedFiscal" class="menuSelecionado">
            <!-- Adicionar novas funcionalidades para tela -->
            <a class="rota" routerLink="/fiscal/sped-icms">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Sped Fiscal </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSpedPisCofins" class="menuSelecionado">
            <!-- Adicionar novas funcionalidades para tela -->
            <a class="rota" routerLink="/fiscal/sped-pis-cofins">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Sped Pis/Cofins </span>
            </a>
          </li>

        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-ecommerce-money"></i>
          <span>Financeiro</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFormaPagamento" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/forma-pagamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Forma de Pagamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPrazo" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/prazo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Prazo </span>
            </a>
          </li>

          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/financeiro/pdv-pagamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> PDV Pagamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBanco" class="menuSelecionado" >
            <a class="rota" routerLink="/financeiro/banco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Banco </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarInstrucaoBancaria" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/instrucao-bancaria">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Instrução Bancária </span>
            </a>
          </li>

          <li  class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/retorno-bancario">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Retorno Bancário </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarMotivoDevolucaoCheque" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/motivo-devolucao-cheque">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Motivo Dev. de Cheque </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPlanoContas" class="menuSelecionado" >
            <a class="rota" routerLink="/financeiro/plano-contas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Plano de Contas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarContaLancamento" class="menuSelecionado" >
            <a class="rota" routerLink="/financeiro/conta">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Conta de Lançamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDuplicataReceber" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/duplicata-receber">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Receber </span>
            </a>
          </li>

          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/financeiro/baixa-parcela-receber">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Baixa Parcela Receber </span>
            </a>
          </li>

          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/financeiro/gerar-remessa">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Gerar Remessa </span>
            </a>
          </li>

          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/financeiro/baixar-retorno">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Baixar Retorno </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDuplicataPagar " class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/duplicata-pagar">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Pagar </span>
            </a>
          </li>
          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarBaixaParcelaPagar" -->

          <li>
            <a class="rota" routerLink="/financeiro/baixa-parcela-pagar" class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Baixa Parcela Pagar </span>
            </a>
          </li>


          <li *ngIf="globalsVariablesService.getPermissoes.visualizarLancamentoFinanceiro" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/lancamentos-financeiros">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Lançamentos Financeiros </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarEstornoLancamentoFinanceiro"
            class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/estorno-lancamento-financeiro">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Estorno Lanç. Financeiro </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/transferencia-contas" class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Transferência Contas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFechamentoConta" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/fechamento-conta">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fechamento da Conta </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFaturamento" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/faturamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Faturamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFaturamento" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/faturamento-nota">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Faturamento Nota </span>
            </a>
          </li>

        </ul>
      </li>

      <!--    <li *ngIf="globalsVariablesService.setupModel.tipoObjeto == 'V'" class="treeview">-->
      <li class="treeview">
        <a>
          <i class="fi-dashboard-speedometer-tool"></i>
          <span>Veículos</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFabricanteVeiculo" class="menuSelecionado">
            <a class="rota" routerLink="/veiculos/fabricante">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fabricante </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarModeloVeiculo" class="menuSelecionado">
            <a class="rota" routerLink="/veiculos/modelo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Modelo </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarVeiculo" class="menuSelecionado">
            <a class="rota" routerLink="/veiculos/veiculo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Veículo </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarVeiculo" class="menuSelecionado">
            <a class="rota" routerLink="/veiculos/veiculo-produto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Veículo Produto </span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="globalsVariablesService.setupModel.tipoObjeto == 'E'" class="treeview">
        <a>
          <i class="fi-ecommerce-trolley"></i>
          <span>Equipamentos</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarModeloEquipamento" class="menuSelecionado">
            <a class="rota" routerLink="/equipamentos/modelo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Modelo </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarEquipamento" class="menuSelecionado">
            <a class="rota" routerLink="/equipamentos/equipamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Equipamento </span>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-ecommerce-padlock"></i>
          <span>Segurança</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupoLoja" class="menuSelecionado">
            <a class="rota" routerLink="/seguranca/grupo-loja">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo Loja </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPermissao" class="menuSelecionado">
            <a class="rota" routerLink="/seguranca/permissao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Permissões </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarUsuario" class="menuSelecionado">
            <a class="rota" routerLink="/seguranca/usuario">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Usuário </span>
            </a>
          </li>

          <li class="menuSelecionado">
            <a class="rota" routerLink="/seguranca/usuario-aplicativo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Usuário Aplicativo </span>
            </a>
          </li>

        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-dashboard-rounded-paper-with-text-lines-and-one-folded-corner"></i>
          <span>Relatórios</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioCliente" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-clientes">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Cliente</span>
            </a>
          </li>

          <li class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-fornecedores">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fornecedores</span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioProduto" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-produtos">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Produto </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioProduto" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-nfe-compara">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Comparar Preço NFE </span>
            </a>
          </li>

          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/relatorios/rel-inventario">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Inventário </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioAuditoriaEstoque" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-estoque-auditoria">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Auditoria Estoque </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioEtiqueta" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-etiqueta-produto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Etiqueta </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioVendas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-vendas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Relatório Vendas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioVendas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-xml-vendas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Relatório Xml Vendas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioVendas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-comissoes">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Relatório Comissões</span>
            </a>
          </li>


          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioVendas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-contratos">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Relatório Contratos </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioResumoVendas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-resumo-vendas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Resumo de Vendas </span>
            </a>
          </li>
          <li  class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/relatorios/rel-vendas-pis-cofins">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Relatório de Vendas Pis e Cofins </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarMinutaDespacho" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/minuta-despacho">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Minuta Despacho </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioCompras" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-compra">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Compras </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDuplicatasReceber"
            class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-duplicata-receber">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Receber </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDuplicatasPagar" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-duplicata-pagar">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Pagar </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioPlanoContas " class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-plano-contas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Plano Contas </span>
            </a>
          </li>

          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDuplicatasPagar" -->
          <li class="menuSelecionado" *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco">
            <a class="rota" routerLink="/relatorios/rel-lanc-financeiro">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Lançamentos Financeiro </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioConciliacao" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-conciliacao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Conc. Pagar/Receber </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioProdutoEntradaSaida"
            class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-produto-entrada-saida">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Produtos Entrada/Saida </span>
            </a>
          </li>

          <li class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-produtos-entrada-saida">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Prod. Ent./Sai. Geren.</span>
            </a>
          </li>

          <li class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-produto-endereco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Rel Prod. Endereço Est.</span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioMovimentacaoPlanoContas" class="menuSelecionado">
            <a class="rota" routerLink="/relatorios/rel-movimentacao-plano-contas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Movimentação Plano de Contas</span>
            </a>
          </li>

        </ul>
      </li>

      <li class="treeview">
        <a>
          <i class="fi-creative-settings-2"></i>
          <span>Configurações</span>
          <i class="fa fa-angle-right pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarImportacaoXLS" class="menuSelecionado">
            <a routerLink="/configuracoes/importacao-xls">
              <span class="icon">
                <i class="fa fa-circle-o"></i> Importação XLS</span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSetup" class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/setup">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Setup </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSetup" class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/conf-nfe">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Configurações NFE </span>
            </a>
          </li>


        </ul>
      </li>

    </ul>
    <!-- /.sidebar-menu -->
  </section>
  <!-- /.sidebar -->
</aside>

<!-- Content Wrapper. Contains page content -->
<div [ngClass]="{'content-wrapper': authService.isLoggedIn}">
  <!-- Content Header (Page header) -->
  <!--
          <section class="content-header">
            <h1>
              Page Header
              <small>Optional description</small>
            </h1>
            <ol class="breadcrumb">
              <li><a href="#"><i class="fa fa-dashboard"></i> Level</a></li>
              <li class="active">Here</li>
            </ol>
          </section> -->

  <!-- Main content -->
  <section class="content">

    <router-outlet>

    </router-outlet>

  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->

<!-- Main Footer -
    <footer class="main-footer" *ngIf="authService.isLoggedIn" >

      <div class="pull-right hidden-xs">
        ..
      </div>
      <!-- Default to the right
      <strong>Copyright &copy; 2016 <a href="#">Araguaia Sistemas</a>.</strong>todos os direitos reservados.

    </footer>  -->

<!-- /.control-sidebar -->
<!-- Add the sidebar's background. This div must be placed
       immediately after the control sidebar -->

<div class="control-sidebar-bg" *ngIf="authService.isLoggedIn"></div>

<!-- </div> ./wrapper -->

<!-- REQUIRED JS SCRIPTS -->

<!-- jQuery 2.2.0 -->
<!-- Optionally, you can add Slimscroll and FastClick plugins.
     Both of these plugins are recommended to enhance the
     user experience. Slimscroll is required when using the
     fixed layout. -->
<!--</body> -->
